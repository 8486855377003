import React from 'react';
import IndexPage from 'pages/IndexPage';
import { Switch, Route } from 'react-router-dom';
import MainRoutes from './main';
import ACRoutes from './ac';

export default function AppRouter() {
  return (
    <Switch>
      <Route path="/ac/songs" component={ACRoutes} />
      <Route path="/test" component={MainRoutes} />
      <Route exact path="/" component={IndexPage} />
      <Route to="*">
        <h1>404 Not Found :(</h1>
      </Route>
    </Switch>
  );
}
