// Exposes methods to fetch Lister User resources
import User from 'api/ac/user';
import List from 'api/ac/list';
import ListSong from 'api/ac/listsong';
import ResponseError from './ResponseError';

export async function fetchUser(username) {
  // Check for cached userdata
  const localUserData = JSON.parse(localStorage.getItem('acSongsUserData'));
  if (localUserData?.username === username) return localUserData;
  return User.get(username);
}

export async function fetchUserList(userId) {
  return User.getList(userId);
}

// Returns an array of ListSong = { "id", "checked", "song": { ... } }
export async function fetchListSongsForList(listId) {
  return ListSong.getFromList(listId);
}

export async function authListPin(listId, pin) {
  return List.auth(listId, pin).catch((err) => {
    const modErr = err; // nice...
    if (modErr?.response?.status === 401) modErr.message += ' (Incorrect/Invalid Pin)';
    throw modErr;
  });
}

export async function patchListSong(listSongId, pin, checked) {
  return ListSong.patch(listSongId, pin, checked);
}

// Returns the user data (Holy batman of promises...)
export async function createUserWithList(username, pin) {
  if (!pin || pin.trim() === '')
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(new ResponseError(null, 'Please enter a valid pin')); // ffs
  let userData = null;
  // Create user (Store user in local storage)
  // Create List with user (Store list in local storage)
  // Create all ListSongs from songs, tied to the list
  return (
    User.post(username)
      .catch((err) => {
        // User creation failed
        const usernameError = err.response?.data?.errors?.username;
        if (usernameError && usernameError[0].indexOf('username has already been taken') !== -1) {
          // Maybe use a code??
          // console.debug('user creation failed, attempting to use existing user...');
          return User.get(username);
        }
        return Promise.reject(err); // Pass upward
      })
      .then((resData) => {
        // User created, create a list for the user
        userData = resData;
        return List.post(userData.id, pin);
      })
      .catch((err) => {
        // List creation failed/Or songs request failed/Or adding songs to list failed
        return Promise.reject(err); // Pass upward
      })
      // .then((listData) => {
      .then(() => {
        // List created
        // Songs were added to the list on the backend
        // console.debug('userdata: ', userData);
        // console.debug('listdata: ', listData);
        return userData;
      })
  );
}
