import React, { useState } from 'react';
import PropTypes from 'prop-types';

function LoadableImage({ src, alt }) {
  const [loading, setLoading] = useState(true);
  function handleLoad() {
    setLoading(false);
  }

  const style = {
    width: '100%',
    height: '100%',
    paddingTop: '50%',
    paddingBottom: '50%',
  };

  return (
    <>
      {loading && (
        <div style={style} data-testid="image_loading">
          Loading
        </div>
      )}
      <img
        style={{ display: loading ? 'none' : 'block' }}
        src={src}
        alt={alt}
        onLoad={handleLoad}
      />
    </>
  );
}

LoadableImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default LoadableImage;
