import React from 'react';
import logo from 'svgs/logo.svg';
import { NavLink, Link } from 'react-router-dom';
import ThemeButton from 'components/ThemeButton';

import './Header.css';

export default function Header() {
  return (
    <header className="header">
      <Link to="/" className="header__logo" data-testid="home_link">
        <img src={logo} alt="logo" />
      </Link>
      <NavLink to="/ac/songs" className="header__link" data-testid="acnh_songs_link">
        <div className="header__link__text">ACNH Songs</div>
      </NavLink>
      <ThemeButton className="header__theme_button" />
    </header>
  );
}
