import React from 'react';
import clsx from 'clsx';
import { fetchUser, createUserWithList } from 'api/acsongs';
import { Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import './SongsIndexPage.css';

class SongsIndexPage extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;

    this.usernameInputRef = React.createRef();
    this.state = {
      username: '',
      loading: false,
      errorMessage: location?.state?.errorMessage ?? '',
      goToUser: '',
      pin: '',
    };
  }

  componentDidMount() {
    this.usernameInputRef.current.focus();
  }

  // componentDidUpdate(prevProps, prevState) {
  componentDidUpdate() {
    this.createClicked = false; // disgusting
  }

  handleSearchChange = (e) => {
    this.setState({
      username: e.target.value,
      errorMessage: '',
    });
  };

  handlePinChange = (e) => {
    this.setState({
      pin: e.target.value,
      errorMessage: '',
    });
  };

  handleSearch = (e) => {
    const { username, loading } = this.state;
    e.preventDefault();
    if (loading) return;

    if (this.createClicked) {
      this.handleCreate(e);
      return;
    }

    this.setState({ loading: true });

    fetchUser(username)
      .then((userData) => {
        // console.debug('found user: ', userData);
        this.setState({ goToUser: userData });
      })
      .catch((err) => {
        this.setState({ loading: false });

        if (err?.response?.status === 404) {
          return this.setState({
            errorMessage: `User: ${username} was not found...`,
          });
        }
        return this.setState({
          errorMessage: err.message,
        });
      });
    // }).finally(() => this.setState({ loading: false }));
  };

  handleCreate = (e) => {
    const { username, pin, loading } = this.state;
    if (loading) return;
    e.preventDefault();
    this.setState({ loading: true });

    createUserWithList(username, pin)
      .then((userData) => {
        const songsUserData = { ...userData, pin };
        localStorage.setItem('acSongsUserData', JSON.stringify(songsUserData));
        this.setState({ goToUser: songsUserData, username: userData.username });
      })
      .catch((err) => {
        // console.debug('createusererror: ', err, err?.response);

        return this.setState({ errorMessage: err.message, loading: false });
      });
    // }).finally(() => this.setState({ loading: false }));
  };

  render() {
    const { errorMessage, goToUser, username, loading, pin } = this.state;
    if (goToUser)
      return (
        <Redirect
          to={{ pathname: `/ac/songs/user/${goToUser.username}`, state: { userData: goToUser } }}
          push
        />
      );

    return (
      <div className="content">
        {loading !== false && (
          <div className="loading_panel loading_panel--fullscreen">
            {loading !== true ? loading : 'Loading...'}
          </div>
        )}
        <h2>Ac Song Index page</h2>
        <form className="form username_form" onSubmit={this.handleSearch}>
          <label
            className={clsx('form__label username_form__label', {
              error: errorMessage,
              'username_form__label--error': errorMessage,
            })}
            htmlFor="username"
          >
            {errorMessage || 'Enter a username to see their Animal Crossing songs list'}
            <input
              ref={this.usernameInputRef}
              className="form__input username_form__input"
              disabled={loading}
              id="username"
              type="text"
              minLength="3"
              maxLength="150"
              placeholder="username"
              pattern="[a-zA-Z0-9]+"
              title="Alphanumerics please"
              required
              value={username}
              onChange={this.handleSearchChange}
              autoComplete="off"
            />
          </label>
          <label className={clsx('form__label username_form__label')} htmlFor="pin">
            Enter a 4 digit pin (If you are creating a new user)
            <input
              className="form__input username_form__input"
              disabled={loading}
              id="pin"
              type="text"
              minLength="4"
              maxLength="10"
              pattern="[0-9]*"
              title="Pin must only be digits"
              placeholder="1234"
              value={pin}
              onChange={this.handlePinChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') this.createClicked = true;
              }}
              autoComplete="off"
            />
          </label>
          <button
            className="button form__button form__button form__button--submit username_form__submit"
            disabled={loading}
            type="submit"
            value="submit"
          >
            Search
          </button>
          <button
            className="button form__button form__button--submit username_form__submit"
            disabled={loading}
            onClick={() => {
              this.createClicked = true;
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') this.createClicked = true;
            }}
            type="submit"
            value="create"
          >
            Create
          </button>
        </form>
      </div>
    );
  }
}

SongsIndexPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      errorMessage: PropTypes.string,
    }),
  }),
};

export default SongsIndexPage;
