import React from 'react';

// Get theme from user app defined preference (Higher priority)
function getUserTheme() {
  return localStorage.getItem('user-theme');
}
function setUserTheme(theme) {
  localStorage.setItem('user-theme', theme);
}

// Get theme from user system preferences
function getDefaultTheme() {
  // Return user app defined preference if set
  const userTheme = getUserTheme();
  if (userTheme) return userTheme;

  // Use color scheme preference, otherwise use dark mode
  const prefLightMode = window.matchMedia('(prefers-color-scheme: light)').matches;
  return prefLightMode ? 'light' : 'dark';
}

const themes = {
  dark: 'dark',
  light: 'light',
};

const ThemeContext = React.createContext({
  theme: getDefaultTheme(),
  toggleTheme: () => {},
});

export { ThemeContext, themes, getUserTheme, setUserTheme, getDefaultTheme };