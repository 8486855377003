import React from 'react';
import Header from 'components/Header';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ThemeContext, themes, getDefaultTheme, setUserTheme } from 'themeContext';
import AppRouter from 'routes';
import './App.css';
import ErrorBoundary from 'components/ErrorBoundary';
import { PropTypes } from 'prop-types';

class App extends React.Component {
  constructor(props) {
    super(props);

    const themeContextValue = {
      theme: getDefaultTheme(),
      toggleTheme: this.toggleTheme.bind(this),
    };

    this.state = {
      themeContextValue,
    };

    // console.debug('app constructed');
  }

  componentDidMount() {
    this.setBodyTheme();
    const { appEnv } = this.props;
    if (appEnv === 'local') {
      document.body.classList.add('LOCALDEV');
    }
    // console.debug('app mounted');
  }

  /*
  shouldComponentUpdate(nextProps, nextState) {
    console.debug('app shouldupdate');
  }
  */

  // componentDidUpdate(prevProps, prevState) {
  componentDidUpdate() {
    this.setBodyTheme();
    // console.debug('app updated');
  }

  componentWillUnmount() {
    // console.debug('app unmounting');
  }

  setBodyTheme() {
    const { themeContextValue } = this.state;
    const { theme } = themeContextValue;
    const darkClassName = 'dark-mode--activated';
    if (theme === themes.dark) document.body.classList.add(darkClassName);
    else document.body.classList.remove(darkClassName);
  }

  toggleTheme() {
    this.setState((state) => {
      const newTheme = state.themeContextValue.theme === themes.dark ? themes.light : themes.dark;
      setUserTheme(newTheme); // Store user theme preference
      return {
        themeContextValue: {
          ...state.themeContextValue,
          theme: newTheme,
        },
      };
    });
  }

  render() {
    const { themeContextValue } = this.state;
    const { children } = this.props;
    return (
      <CookiesProvider>
        <BrowserRouter>
          <ThemeContext.Provider value={themeContextValue}>
            <div className="app">
              <ErrorBoundary name="header_boundary">
                <Header />
              </ErrorBoundary>
              <ErrorBoundary name="app_router_boundary">
                <AppRouter />
              </ErrorBoundary>
            </div>
            {children}
          </ThemeContext.Provider>
        </BrowserRouter>
      </CookiesProvider>
    );
  }
}

App.propTypes = {
  appEnv: PropTypes.string,
  children: PropTypes.element,
};

export default App;
