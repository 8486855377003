import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SongsIndexPage from 'pages/ac/songs/SongsIndexPage';
import UserPage from 'pages/ac/songs/UserPage';

// Animal crossing related routes
export default function ACRoutes() {
  return (
    <Switch>
      <Route path="/ac/songs/user/:user" component={UserPage} />
      <Route path="/ac/songs" component={SongsIndexPage} />
    </Switch>
  );
}
