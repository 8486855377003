import axios from 'axios';

async function get(username) {
  const res = await axios.get(`/test/lister/users/${username}`);
  return res.data.data;
}

async function getList(userId) {
  const payload = { params: { user_id: userId } };
  const res = await axios.get('/test/lister/acmusic/lists', payload);
  return res.data.data[0];
}

async function post(username) {
  const res = await axios.post('/test/lister/users', { username });
  return res.data.data;
}

export default {
  get,
  getList,
  post,
};
