import React from 'react';
import {
  authListPin,
  fetchUser,
  fetchUserList,
  fetchListSongsForList,
  patchListSong,
} from 'api/acsongs';
import { Redirect } from 'react-router-dom';
import UserList from 'components/acsongs/UserList';
import ListSongsFilterBar from 'components/acsongs/ListSongsFilterBar';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';

import lockedIcon from 'svgs/locked.svg';
import unlockedIcon from 'svgs/unlocked.svg';
import styles from './UserPage.module.css';

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    const userData = props?.location?.state?.userData;
    const { user } = props?.match?.params;
    const acSongsUserData = JSON.parse(localStorage.getItem('acSongsUserData'));

    this.state = {
      loading: true, // Boolean cast... i hate me
      userNotFound: false,
      userData,
      listData: null,
      listSongs: null,
      pin: acSongsUserData?.username === user ? acSongsUserData?.pin : '',
      errorMessage: '',
      locked: true,
    };

    this.pinForm = React.createRef();
    this.pinInput = React.createRef();
  }

  async componentDidMount() {
    const { userData } = this.state;
    if (userData) {
      // Userdata was most likely passed through router
      this.fetchUserList(userData);
    } else {
      // Fetch the user data from the url param
      const { match } = this.props;
      const { user: username } = match.params;

      try {
        const userRes = await fetchUser(username);
        // console.debug('userpage: ', userRes);
        this.setState({ userData: userRes });
        await this.fetchUserList(userRes);
      } catch (err) {
        // console.debug('userpage error: ', err);
        this.setState({ userNotFound: true });
      }
    }
  }

  handleChangePin = (e) => {
    const { userData } = this.state;
    const acSongsUserData = { ...userData, pin: e.target.value };
    localStorage.setItem('acSongsUserData', JSON.stringify(acSongsUserData));
    this.setState({ errorMessage: '', pin: e.target.value, locked: true });
  };

  handlePinSubmit = (e) => {
    e.preventDefault();
    this.updateLocked();
    return false;
  };

  handleFilterChange = (filteredListSongs) => {
    this.setState({ listSongs: filteredListSongs, errorMessage: '' });
  };

  fetchUserList = async (userData) => {
    // console.debug('fetching list for: ', userData);
    try {
      const listData = await fetchUserList(userData.id);
      const listSongs = await fetchListSongsForList(listData.id);

      const { pin } = this.state;
      listSongs.sort((a, b) => a.song.name.localeCompare(b.song.name));
      this.setState({ listData, listSongs, loading: false });
      if (pin !== '') this.updateLocked();
    } catch (err) {
      // console.debug('list fetch error: ', err);
      this.setState({ loading: false, errorMessage: err.message });
    }
  };

  // Update the locked state by checking/authing the pin
  // Returns the new locked state
  updateLocked = async () => {
    if (!this.pinForm.current.reportValidity()) return true;
    const { listData, pin } = this.state;
    let locked = true;
    let errorMessage = '';

    try {
      const res = await authListPin(listData.id, pin);
      if (res.id === listData.id) {
        locked = false;
      } else {
        errorMessage = 'Error: Honestly not sure how this went wrong... (Try refreshing?)';
      }
    } catch (err) {
      errorMessage = err.message;
    }

    // Focus the pin form if the lock test failed
    if (locked) {
      this.pinInput.current.focus();
    }
    // Set the state
    this.setState({ locked, errorMessage });
    return locked;
  };

  handleSongClicked = async (listSongId) => {
    const { listSongs: origListSongs, locked } = this.state;
    // Check pin
    if (locked && (await this.updateLocked())) return;
    const listSongIndex = origListSongs.findIndex((l) => l.id === listSongId);
    // Check if listsong is still pending a request
    if (origListSongs[listSongIndex].dirty) return;

    const origListSongChecked = origListSongs[listSongIndex].checked;
    const toggledChecked = !origListSongChecked; // nice toggle kekw
    this.setState(() => {
      origListSongs[listSongIndex].checked = toggledChecked;
      origListSongs[listSongIndex].dirty = true;
      return { listSongs: origListSongs };
    });

    // Send toggled listsong
    const { pin } = this.state;
    let errorMessage = '';

    try {
      const res = await patchListSong(listSongId, pin, toggledChecked);
      // console.debug('updated songlist res: ', res);
      origListSongs[listSongIndex].checked = res.checked;
    } catch (err) {
      // Revert
      // console.debug('updating songlist err: ', err);
      origListSongs[listSongIndex].checked = origListSongChecked;
      errorMessage = err.message;
    }

    // Update state
    this.setState(() => {
      origListSongs[listSongIndex].dirty = false;
      return { listSongs: origListSongs, errorMessage };
    });
  };

  render() {
    const {
      listData,
      pin,
      errorMessage,
      listSongs,
      userData,
      loading,
      userNotFound,
      locked,
    } = this.state;

    return (
      <div className="content">
        {userNotFound && (
          <Redirect to={{ pathname: '/ac/songs', state: { errorMessage: 'User not found...' } }} />
        )}
        {loading && (
          <div className="loading_panel loading_panel--fullscreen" data-testid="page_loading">
            Loading...
          </div>
        )}
        {userData && listData && listSongs && (
          <div className={styles.header}>
            <div className={styles.headerUtilities}>
              <form
                onSubmit={this.handlePinSubmit}
                className={clsx(styles.pinForm, !locked && styles.pinFormUnlocked)}
                ref={this.pinForm}
              >
                <input
                  name="pin"
                  className={clsx(styles.pinFormInput)}
                  type="password"
                  placeholder="Pin (To edit list)"
                  onChange={this.handleChangePin}
                  required
                  autoComplete="off"
                  pattern="[a-zA-Z0-9]+"
                  title="Alphanumerics only"
                  minLength="4"
                  value={pin}
                  ref={this.pinInput}
                />
                <img
                  src={locked ? lockedIcon : unlockedIcon}
                  alt={locked ? 'List Locked' : 'List Unlocked'}
                  className={!locked ? styles.pinFormIconUnlocked : styles.pinFormIcon}
                />
              </form>
              <div className={styles.filterSortContainer}>
                <ListSongsFilterBar
                  listSongs={listSongs}
                  onChange={this.handleFilterChange}
                  placeholder="Filter songs"
                />
              </div>
            </div>
            <h2 className={styles.heading}>
              {`${userData.username.charAt(0).toUpperCase() + userData.username.slice(1)}'s songs`}
            </h2>
            <h6 className={styles.heading}>Dim green = Acquired</h6>
          </div>
        )}
        <div className={styles.errorMessage}>{errorMessage}</div>
        {listData && listSongs && (
          <UserList listSongs={listSongs} songClicked={(e, id) => this.handleSongClicked(id)} />
        )}
      </div>
    );
  }
}

UserPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      user: PropTypes.string.isRequired,
    }),
  }),
};

export default UserPage;
