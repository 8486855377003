export default class ResponseError extends Error {
  /**
   * Custom response error for storing a meaningful error message
   * @param {*} response Axios response
   * @param {string} message
   * @param  {...any} args Forwarded to error
   */
  constructor(response, message = '', ...args) {
    super(message, ...args);
    this.name = 'ResponseError';
    this.response = response;
    this.message = message;
  }
}
