// Custom file to set global variables and configurations for runtime
import axios from 'axios';
import ResponseError from 'api/ResponseError';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers.Accept = 'application/json';

axios.interceptors.request.use(
  (config) => {
    console.debug('axios request: ', config);
    return config;
  },
  (error) => {
    console.debug('axios request error: ', error, error?.request);
    return Promise.reject(new ResponseError(error));
  }
);

axios.interceptors.response.use(
  (response) => {
    console.debug('axios response: ', response);
    return response;
  },
  (error) => {
    console.debug('axios error: ', error, error?.response);

    let errorMessage = 'Error: ';
    if (axios.isCancel(error)) errorMessage += 'Request canceled';
    else if (error?.response?.status === 429)
      errorMessage += 'Too many requests! Please take it easy';
    else if (error?.response?.status === 401) errorMessage += 'Unauthorized';
    else if (error?.response?.data?.errors)
      errorMessage += JSON.stringify(error.response.data.errors);
    else if (error?.response?.data?.message) errorMessage += error.response.data.message;
    else errorMessage += error?.response?.message ?? 'An unknown error has occured...';

    return Promise.reject(new ResponseError(error?.response, errorMessage));
  }
);
