import axios from 'axios';

function processListSongData(listSong) {
  return {
    ...listSong,
    checked: listSong.checked === 1 || listSong.checked === true || listSong.checked === 'true',
  };
}

async function getFromList(listId) {
  const payload = { params: { expand: 'song', list_id: listId, pag_count: 200 } };
  const res = await axios.get('/test/lister/acmusic/listsongs', payload);
  const { data } = res.data;
  return data.map(processListSongData);
}

async function patch(id, pin, checked) {
  const response = await axios.patch(`/test/lister/acmusic/listsongs/${id}`, { pin, checked: checked ? 1 : 0 });
  return processListSongData(response.data.data);
}

export default {
  patch,
  getFromList,
};
