import React from 'react';

export default function IndexPage() {
  return (
    <div className="content">
      <h2>Main Lister Landing page</h2>
      <h4>Under Construction</h4>
    </div>
  );
}
