import axios from 'axios';

async function post(userId, pin) {
  const res = await axios.post('/test/lister/acmusic/lists', {
    user_id: userId,
    pin,
  });
  return res.data.data;
}

async function auth(listId, pin) {
  const res = await axios.post(`/test/lister/acmusic/lists/${listId}/auth`, { pin });
  return res.data.data;
}

export default {
  auth,
  post,
};
