import React from 'react';
import PropTypes from 'prop-types';
import sendError from 'api/ErrorLogger';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorSent: false,
      errorData: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: Make api to receive this error
    sendError(error, errorInfo)
      .then((errorData) => {
        this.setState({ errorData, errorSent: true });
      })
      .catch((exception) => {
        // Nothing...
      });
  }

  refreshButtonClicked = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  render() {
    const { hasError, errorSent, errorData } = this.state;
    const { children, name } = this.props;
    if (hasError) {
      return (
        <div style={styles}>
          <h1>Something went wrong :(</h1>
          {!errorSent && <h2>{name}</h2>}
          {errorSent && (
            <>
              <h2>
                <span>Incidence Number: </span>
                <span style={{ fontSize: '1.2em', color: '#a00', margin: '1rem' }}>
                  {errorData.id}
                </span>
              </h2>
              <h5>If possible please DM Jazy about this error number</h5>
            </>
          )}
          <h3>Refresh the page to hopefully fix this issue</h3>
          <button className="button form__button" type="button" onClick={this.refreshButtonClicked}>
            Refresh
          </button>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
};

export default ErrorBoundary;
