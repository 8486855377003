import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './ListSong.css';
import LoadableImage from 'components/general/LoadableImage';

/**
 *
 * @param {songData} { "id", "name", "img_url" }
 * @param {checked} "0" | "1"
 * @param {onClick} callback
 */
function ListSong({ 'data-testid': dataTestid, songData, checked, onClick, className }) {
  return (
    <button
      type="button"
      className={clsx('song', { 'song--checked': checked }, className)}
      onClick={onClick}
      data-testid={dataTestid}
    >
      <div className="song__img">
        <LoadableImage src={songData.img_url} alt={songData.name} />
      </div>
      <div className="song__name">{songData.name}</div>
    </button>
  );
}

ListSong.propTypes = {
  songData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    img_url: PropTypes.string,
  }),
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  'data-testid': PropTypes.number,
};

export default ListSong;
