import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './FilterBar.module.css';

/**
 * @callback onChangeCallback
 * @param {*} Event
 */
/**
 * Basic filter bar input
 * @param {*} props
 * @param {onChangeCallback} props.onChange
 * @param {boolean} props.disabled
 */
function FilterBar({ onChange, disabled, placeholder, className }) {
  return (
    <input
      placeholder={placeholder}
      type="text"
      onChange={onChange}
      disabled={disabled}
      className={clsx(styles.filterBar, className)}
    />
  );
}

FilterBar.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default FilterBar;
