import React from 'react';
import { Route, Switch } from 'react-router-dom';
import IndexPage from 'pages/IndexPage';
import Test1 from 'pages/test1';
import Test2 from 'pages/test2';
import Test3 from 'pages/test3';

export default function MainRoutes() {
  return (
    <Switch>
      <Route path="/test">
        <IndexPage />
      </Route>
      <Route path="/test/1">
        <Test1 />
      </Route>
      <Route path="/test/2">
        <Test2 />
      </Route>
      <Route path="/test/3">
        <Test3 />
      </Route>
    </Switch>
  );
}
