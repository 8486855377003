import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'themeContext';
import clsx from 'clsx';

import sunSvg from 'svgs/sun.svg';
import moonSvg from 'svgs/moon.svg';
import styles from './ThemeButton.module.css';

function ThemeButton({ className }) {
  const themeContextValue = useContext(ThemeContext);

  function handleClick() {
    themeContextValue.toggleTheme();
  }

  return (
    <div className={clsx(className, styles.button)}>
      <div
        role="button"
        tabIndex={0}
        data-testid="button"
        className={styles.iconContainer}
        onClick={handleClick}
        onKeyPress={(e) => e.key === 'Enter' && handleClick(e)}
      >
        <img className={styles.moon} src={moonSvg} alt="Moon" />
        <img className={styles.sun} src={sunSvg} alt="Sun" />
      </div>
    </div>
  );
}

ThemeButton.propTypes = {
  className: PropTypes.string,
};

export default ThemeButton;
