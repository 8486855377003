import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ListSong from './ListSong';

import './UserList.css';

/**
 * songClickedCallback
 * @param {Event} event
 * @param {number} id
 */
/**
 * Render a list of songlists
 * @param props
 * @param {Object[]} props.listSongs Array of songlists
 * @param {number} props.listSongs.id
 * @param {boolean} props.listSongs.checked
 * @param {boolean} props.listSongs.filtered (Should the listsong be hidden)
 * @param {Object} props.listSongs.song
 * @param {string} props.listSongs.song.name
 * @param {songClickedCallback} props.songClicked (Called when a song is clicked with params: event, SongList.id)
 *
 */
function UserList({ listSongs, songClicked, className }) {
  return (
    <div className={clsx('userList', className)}>
      {listSongs.map((listSong) => (
        <ListSong
          key={listSong.id}
          data-testid={listSong.id}
          onClick={(e) => songClicked(e, listSong.id)}
          onKeyPress={(e) => e.key === 'Enter' && songClicked(e, listSong.id)}
          className={clsx(
            'userList__listSong',
            listSong.filtered && 'userList__listSong--filtered'
          )}
          songData={listSong.song}
          checked={listSong.checked}
        />
      ))}
    </div>
  );
}

UserList.propTypes = {
  listSongs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      song: PropTypes.object,
      checked: PropTypes.bool,
      filtered: PropTypes.bool,
    })
  ),
  songClicked: PropTypes.func,
  className: PropTypes.string,
};

export default UserList;
