import axios from 'axios';
import ResponseError from './ResponseError';

async function sendError(error, extra, app = 'Lister ReactJS') {
  // Destruct the error into an object
  const errorObject = {
    name: error.name,
    message: error.message,
    fileName: error?.fileName,
    lineName: error?.lineName,
    columnNumber: error?.coluumnNumber,
    stack: error?.stack,
  };
  const currentTime = new Date().toISOString();

  try {
    const res = await axios.post('/meta/logger/errors', {
      error: JSON.stringify(errorObject),
      extra: JSON.stringify(extra),
      error_date: currentTime,
      app,
    });
    return res.data.data;
  } catch (exception) {
    // tfw the error logger has an error :(
    console.debug(exception);
    throw new ResponseError(exception, ''); // :/
  }
}

export default sendError;
