import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FilterBar from 'components/general/FilterBar';

/**
 * filteredListSongs will be the listSongs array with a new "filtered" key
 * set accordiningly (filtered: true = item should be hidden; was not hit by filter)
 * @callback onChangeCallback
 * @param {Object[]} filteredListSongs
 */

/**
 * Filter bar for list songs HOC
 * @param {Object} props
 * @param {Object[]} props.listSongs
 * @param {Object} props.listSongs.song
 * @param {string} props.listSongs.song.name
 * @param {onChangeCallback} props.onChange
 * @param {boolean} props.disabled
 */
function ListSongsFilterBar({ listSongs, onChange, disabled, className, placeholder }) {
  const [filter, setFilter] = useState('');

  /**
   * Pretty inefficient
   * @param {string} f
   */
  function getFiltered(f) {
    const normFilter = f.toLocaleLowerCase();
    return listSongs.map((e) => {
      const newListSong = { ...e };
      const { song } = newListSong;
      const { name } = song;
      const normName = name.toLocaleLowerCase();

      if (normFilter === '') newListSong.filtered = false;
      else if (normName.indexOf(normFilter) < 0) newListSong.filtered = true;
      else newListSong.filtered = false;

      return newListSong;
    });
  }

  function handleChange(e) {
    const f = e.target.value;
    setFilter(f);
    onChange(getFiltered(f));
  }

  // TODO: Remove!!
  if (filter === 'secreterror') throw new Error('Mama Mia!!');

  return (
    <FilterBar
      placeholder={placeholder}
      onChange={handleChange}
      disabled={disabled}
      value={filter}
      className={className}
    />
  );
}

ListSongsFilterBar.propTypes = {
  listSongs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      checked: PropTypes.bool,
      song: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
      }),
    }).isRequired
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default ListSongsFilterBar;
